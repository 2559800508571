import { render, staticRenderFns } from "./VendorList.vue?vue&type=template&id=5cef3cfa&scoped=true"
import script from "./VendorList.vue?vue&type=script&lang=js"
export * from "./VendorList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cef3cfa",
  null
  
)

export default component.exports