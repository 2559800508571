<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Sekolah
              </span>
            </h3>
            <div class="card-toolbar">
              <button @click="modalShow = !modalShow" href="#"
                class="btn btn-primary font-weight-bolder font-size-sm mr-4">
                Impor Sekolah
              </button>
              <router-link :to="`/vendor/create`" href="#" class="btn btn-success font-weight-bolder font-size-sm">
                Tambah Sekolah Baru</router-link>
            </div>
          </div>
          <div class="card-body pt-4">
            <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"
                    size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <b-row>
                <b-col>
                  <b-form-group label="Code" label-for="code-filter-input" label-cols-sm="3" label-align-sm="right"
                    label-size="sm" class="mb-0">
                    <b-input-group size="sm">
                      <b-form-input id="code-filter-input" v-model="code_filter" type="search"
                        placeholder="Cari Kode Sekolah"></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!code_filter" @click="code_filter = ''">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group label="Name" label-for="code-filter-input" label-cols-sm="3" label-align-sm="right"
                    label-size="sm" class="mb-0">
                    <b-input-group size="sm">
                      <b-form-input id="filter-input" v-model="filter" type="search"
                        placeholder="Cari Nama Sekolah"></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-row>

            <b-table :filter="filter" @filtered="onFiltered" :items="currentVendorList.data" :fields="fields"
              :busy="isLoading" show-empty
              class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
              responsive>
              <template #empty>
                <h6 class="text-center">Tidak ada sekolah ditemukan</h6>
              </template>
              <template #emptyfiltered>
                <h6 class="text-center">Tidak ada sekolah ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>

              <template #cell(action)="data">
                <b-dropdown variant="light"
                  toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon" no-caret right
                  no-flip>
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item p-0">
                      <span @click="sendMail(data.item)" class="navi-link cursor-pointer">
                        <span class="navi-icon">
                          <i class="flaticon2-mail"></i>
                        </span>
                        <span class="navi-text">Kirim Email</span>
                      </span>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item p-0">
                      <span @click="setDetail(data.item)" class="navi-link cursor-pointer">
                        <span class="navi-icon">
                          <i class="flaticon2-edit"></i>
                        </span>
                        <span class="navi-text">Ubah Sekolah</span>
                      </span>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item p-0">
                      <span class="navi-link cursor-pointer" @click="deleteVendor(data.item)">
                        <span class="navi-icon">
                          <i class="flaticon2-trash"></i>
                        </span>
                        <span class="navi-text">Hapus Sekolah</span>
                      </span>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
              </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
              class="my-0"></b-pagination>
          </div>
          <!-- <b-button href="#" variant="primary">Go somewhere</b-button>-->

          <!-- import modal -->
          <b-modal title="Import Sekolah" v-model="modalShow" @ok="submitImport">
            <div class="d-flex flex-row mb-1">
              <b-button href="/Format Data Sekolah UKD Malang 2024.xlsx" class="
                  btn btn-fh btn-light-info btn-hover-info
                  border border-info
                  font-weight-bold
                  px-2 px-lg-5
                ">
                <span class="svg-icon svg-icon-info svg-icon-lg">
                  <inline-svg src="media/svg/icons/General/Clip.svg" />
                </span>
                Format Data Sekolah UKD Malang 2024.xlsx
              </b-button>
            </div>
            <b-form-file v-model="template" :state="Boolean(template)" accept=".xlsx, .xls" class="primary"
              :placeholder="template ? template.name : 'Pilih file template'"
              drop-placeholder="Drop file here..."></b-form-file>
            <!-- <div class="mt-3">
              Selected file: {{ template ? template.name : "" }}
            </div> -->
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { mapGetters, mapState } from "vuex"
import {
  GET_VENDOR,
  SET_SINGLE_VENDOR,
  DELETE_VENDOR,
  IMPORT_VENDOR,
  SEND_MAIL
} from "@/core/services/store/vendor.module"
export default {
  name: "MapelList",
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: '',
      code_filter: '',
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "school_code",
          label: "Kode Sekolah",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "name",
          label: "Nama Sekolah",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "address",
          label: "Alamat",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      modalShow: false,
      template: null,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Daftar Sekolah" }])
    this.getData()
  },
  watch: {
    perPage: function () {
      this.getData()
    },
    currentPage: function () {
      this.getData()
    },
    filter: function () {
      this.getData()
    },
    code_filter: function () {
      this.getData()
    },
  },
  computed: {
    ...mapState({
      errors: state => state.vendor.errors,
    }),
    ...mapGetters(["currentVendorList"]),
    currentUser() {
      return this.$currentUser()
    },
    isSiswa() {
      return false
    },
  },

  methods: {
    removeDuplicate(kelasArr) {
      const arr = kelasArr.map(item => {
        return item.kelas.nama_kelas
      })
      return arr.filter((item, pos, self) => {
        return self.indexOf(item) == pos
      })
    },
    onFiltered(filteredItems) {
      // console.log(filteredItems)
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onFilteredSiswa(filteredItems, item) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      item.totalRows = filteredItems.length
      // item.currentPage = 1
    },
    getData() {
      this.isLoading = true
      // console.log("page", this.currentPage)

      const params = new URLSearchParams()
      params.append("limit", this.perPage)
      params.append("page", this.currentPage)
      if (this.filter) {
        params.append("where['name']", this.filter)
      }

      if (this.code_filter) {
        params.append("where['school_code']", this.code_filter)
      }

      this.$store
        .dispatch(GET_VENDOR, params.toString())
        .then(() => {
          this.isLoading = false
          this.totalRows = this.currentVendorList.total
        })
        .catch(() => {
          this.isLoading = false
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Sekolah`,
            variant: "danger",
            solid: true,
          })
        })
    },
    setActiveTab(event, item) {
      const tab = event.target.closest('[role="tablist"]')
      const links = tab.querySelectorAll(".nav-link")
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active")
      }

      // set clicked tab index to bootstrap tab
      item.tabIndex = parseInt(event.target.getAttribute("data-tab"))

      // set current active tab
      event.target.classList.add("active")
    },
    deleteVendor(item) {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin ingin menghapus Sekolah ini?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.isLoading = true
            this.$store
              .dispatch(DELETE_VENDOR, item._id)
              .then(response => {
                this.getData()
                this.$bvToast.toast(`Berhasil hapus sekolah`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                })
              })
              .catch(() => {
                this.isLoading = false
                this.$bvToast.toast(this.errors, {
                  title: `Gagal menghapus sekolah`,
                  variant: "danger",
                  solid: true,
                })
              })
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          // An error occurred
        })
    },
    setDetail(item) {
      this.$store.dispatch(SET_SINGLE_VENDOR, item)
      // router.push('/users/eduardo')
      this.$router.push(`/vendor/${item._id}/edit`)
    },
    sendMail(item) {
      this.$store.dispatch(SEND_MAIL, {
        email: item.email
      })
        .then(response => {
          this.getData()

          // console.log(response)
          this.$bvToast.toast(`Berhasil mengirim email`, {
            title: response.message,
            variant: "success",
            solid: true,
          })
        })
        .catch(() => {
          this.isLoading = false
          this.$bvToast.toast('Gagal mengirim email', {
            title: `Error`,
            variant: "danger",
            solid: true,
          })
        })
    },
    submitImport() {
      if (this.template) {
        this.isLoading = true
        this.$store
          .dispatch(IMPORT_VENDOR, {
            file: this.template
          })
          .then(response => {
            this.getData()
            this.$bvToast.toast(`Berhasil import sekolah`, {
              title: response.message,
              variant: "success",
              solid: true,
            })
          })
          .catch(() => {
            this.isLoading = false
            this.$bvToast.toast(this.errors, {
              title: `Gagal mengimport sekolah`,
              variant: "danger",
              solid: true,
            })
          })
      } else {
        this.$bvToast.toast("Template import tidak valid", {
          title: `Error`,
          variant: "danger",
          solid: true,
        })
      }
    },
  },
}
</script>

<style scoped></style>
